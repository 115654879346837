.group {
  > * {
    animation: ScrollDownButton 4s ease-in-out infinite;

    @for $i from 1 through 5 {
      &:nth-last-child(#{$i}) {
        animation-delay: $i * 200ms;
      }
    }
  }
}

.dot {
  width: 2px;
  height: 2px;
  border-radius: 50%;
  background-color: currentColor;
}

.text {
  margin: 4px 0 8px;
  animation: none;
}

@keyframes ScrollDownButton {
  0% {
    opacity: 0;
    transform: (translateY(4px));
  }
  20% {
    opacity: 1;
    transform: (translateY(0));
  }
  90% {
    opacity: 1;
    transform: (translateY(0));
  }
  100% {
    opacity: 0;
    transform: (translateY(-4px));
  }
}
