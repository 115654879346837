@import '../../styles/common';

.landing {
  @extend %dark-color;
  --swiper-theme-color: currentColor;
  --swiper-pagination-bullet-size: 8px;
  --swiper-pagination-bullet-vertical-gap: 8px;
  --swiper-pagination-bullet-inactive-color: transparent;
  --swiper-pagination-bullet-inactive-opacity: 1;
  --padding: 32px;
  height: 100%;
  position: absolute;
  transition: background-color 0.3s, color 0.3s;
  width: 100%;
  text-align: center;

  :global(.swiper-slide) {
    overflow: hidden;
  }
}

.swiper {
  height: 100%;
  width: 100%;
}

.bg {
  @extend %dark;
  background-attachment: fixed;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  bottom: initial;
  height: 120vh;
  left: 0;
  opacity: 0;
  position: fixed;
  right: 0;
  top: 0;
  transition: opacity 1s 0.1s, transform 0.5s ease-in-out;

  .hide & {
    transform: translateY(-100%);
  }
}

.introSection,
.actionSection {
  width: 100%;
  height: calc(100% - var(--header-height) - 75px);
  position: absolute;
}

.actionSection {
  @include mobile {
    margin-top: 40px;
  }
}

.actionTitle {
  max-width: 656px;
}

.description {
  max-width: 720px;
}

.reels {
  opacity: 0;
  transition: opacity 0.5s 1s;
}

.show {
  opacity: 1;
}

.scrollDownButtonContainer {
  bottom: 24px;
  left: 0;
  // pointer-events: none;
  position: absolute;
  width: 100%;
  z-index: z-index(1);

  @include desktop {
    bottom: var(--header-height);
    // pointer-events: auto;
  }
}

.playButton {
  position: relative;
  width: 80px;
  height: 80px;
}

.videoPlayer {
  &:not(.fullscreen) {
    opacity: 0;
    pointer-events: none;
    width: 1px;
    height: 1px;
    overflow: hidden;
  }
}
